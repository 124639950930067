module.exports={
    "locale": "it-IT",
    "assets": "invalsi",
    "showPassword": true,
    "loginMode": "online",
    "maintenance": false,
    "displayAttemptAccounts": false,
    "displayUnavailableAssignments": false,
    "api":{
      "login": "https://invalsi-benchmark.invalsi.taocloud.org/__r/xnt/api/v1/auth/token",
      "logout": "https://invalsi-benchmark.invalsi.taocloud.org/__r/xnt/api/v1/auth/logout",
      "refreshToken": "https://invalsi-benchmark.invalsi.taocloud.org/__r/xnt/api/v1/auth/refresh-token",
      "assignments": "https://invalsi-benchmark.invalsi.taocloud.org/__r/xnt/api/v1/assignments",
      "lti": "https://invalsi-benchmark.invalsi.taocloud.org/__r/xnt/api/v1/assignments/{assignmentId}/lti-link"
    },
    "return_url": "/index.html"
  }